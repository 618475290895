import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Menu, X, Home } from 'lucide-react';
import { useTranslation } from 'react-i18next';

function Header() {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState('en');
  const [isNavSticky, setIsNavSticky] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLang(lng);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsNavSticky(window.scrollY > 100);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('#menu-button') && !event.target.closest('#mega-menu')) {
        setIsMenuOpen(false);
      }
    };
    if (isMenuOpen) {
      window.addEventListener('click', handleClickOutside);
    }
    return () => window.removeEventListener('click', handleClickOutside);
  }, [isMenuOpen]);

  return (
    <header className={`fixed w-full z-50 transition-all duration-300 ${isNavSticky ? 'bg-black bg-opacity-80 backdrop-filter backdrop-blur-lg' : 'bg-transparent'}`}>
      <nav className="container mx-auto px-6 py-4 flex justify-between items-center">
        {/* Menu Icon */}
        <button
          id="menu-button"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="text-white focus:outline-none"
          aria-label="Toggle Menu"
        >
          {isMenuOpen ? <X size={32} /> : <Menu size={32} />}
        </button>

        {/* Logo */}
        <Link to="/" className="flex items-center">
          <img src="/images/logo.png" alt="Codolie Logo" className="h-12 w-auto mr-2" />
          <motion.h1
            className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600 font-display"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Codolie
          </motion.h1>
        </Link>

        {/* Language Selector and Client Login */}
        <div className="flex items-center space-x-4">
          <select
            value={lang}
            onChange={(e) => changeLanguage(e.target.value)}
            className="bg-purple-600 text-white p-1 rounded"
            aria-label={t('langSwitcher')}
          >
            <option value="en">EN</option>
            <option value="fr">FR</option>
            <option value="es">ES</option>
          </select>
          <Link to="/temporary" className="hidden md:block">
            <motion.button
              className="bg-purple-600 text-white px-4 py-2 rounded-md hover:bg-purple-700 transition-colors duration-300 ease-in-out"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Client Login
            </motion.button>
          </Link>
        </div>
      </nav>

      {/* Simplified Mega Menu with Animation */}
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            id="mega-menu"
            className="absolute top-full left-0 w-full bg-gradient-to-b from-black to-purple-900 text-white"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="container mx-auto px-6 py-8">
              <ul className="flex flex-col items-center space-y-6">
                <li>
                  <Link 
                    to="/rentals" 
                    className="flex items-center hover:text-purple-400 transition-colors"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <Home size={24} className="mr-2" />
                    <span className="text-lg font-semibold">{t('nav.rentals')}</span>
                  </Link>
                </li>
                {/* Add more links here if needed in the future */}
              </ul>
              {/* Client Login Button (Visible on Mobile Only) */}
              <div className="mt-8 text-center">
                <Link to="/temporary" onClick={() => setIsMenuOpen(false)}>
                  <motion.button
                    className="bg-purple-600 text-white px-6 py-3 rounded-full hover:bg-purple-700 transition-colors duration-300 ease-in-out"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    Client Login
                  </motion.button>
                </Link>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
}

export default Header;
