// ThankYou.js
import React from 'react';
import { Link } from 'react-router-dom';

function ThankYou() {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white">
      <div className="text-center">
        <h1 className="text-4xl font-bold mb-4">Thank You!</h1>
        <p className="text-xl mb-8">We have received our team and will get back to you soon.</p>
        <Link to="/" className="bg-purple-600 px-6 py-3 rounded-full text-lg font-semibold hover:bg-purple-700 transition-colors">
          Go Back to Home
        </Link>
      </div>
    </div>
  );
}

export default ThankYou;
