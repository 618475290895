// TemporaryPage.js
import React from 'react';
import { Link } from 'react-router-dom';

function TemporaryPage() {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-b from-gray-900 to-black text-white">
      <header className="flex items-center justify-between w-full max-w-6xl px-6 py-4">
        <Link to="/" className="flex items-center">
          <img src="/images/logo.png" alt="Codolie Logo" className="h-12 w-auto mr-2" />
          <h1 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600 font-display">
            Codolie
          </h1>
        </Link>
      </header>

      <div className="flex flex-col items-center justify-center text-center px-6">
        <h2 className="text-4xl font-bold mb-6">We Are Working on This Feature</h2>
        <p className="text-xl mb-8">We appreciate your interest! Please leave your contact information, and we will get in touch as soon as it's ready.</p>
        
        <form 
          action="https://formspree.io/f/your-form-id" 
          method="POST" 
          className="w-full max-w-md space-y-4"
        >
          <input type="text" name="name" placeholder="Your Name" className="w-full p-3 rounded bg-gray-800 text-white" required />
          <input type="email" name="email" placeholder="Your Email" className="w-full p-3 rounded bg-gray-800 text-white" required />
          <textarea name="message" placeholder="Any specific requests or questions?" className="w-full p-3 rounded bg-gray-800 text-white" rows="4"></textarea>
          
          <button type="submit" className="w-full bg-purple-600 text-white py-2 rounded-full hover:bg-purple-700 transition-colors">
            Contact Me
          </button>
        </form>
        
        <Link to="/" className="mt-8 text-blue-400 hover:underline">Return to Home</Link>
      </div>
    </div>
  );
}

export default TemporaryPage;
