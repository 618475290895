import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowUp, Linkedin, Globe, Smartphone, Cloud, Link as LinkIcon } from 'lucide-react';
import { Link } from 'react-router-dom';
import Header from './components/Header';

const serviceIcons = {
  web: <Globe className="w-8 h-8" />,
  mobile: <Smartphone className="w-8 h-8" />,
  cloud: <Cloud className="w-8 h-8" />,
  web3: <LinkIcon className="w-8 h-8" />
};

function MainPage() {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState('en');
  const [isNavSticky, setIsNavSticky] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLang(lng);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsNavSticky(window.scrollY > 100);
      setShowScrollTop(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="bg-black text-white min-h-screen font-sans no-drag">
      {/* Use the Header component */}
      <Header />

      <main className="overflow-x-hidden no-drag">
        <section id="hero" className="min-h-screen flex items-center justify-center relative overflow-hidden pt-20 md:pt-0">
          <div className="absolute inset-0 z-0">
            <div className="absolute inset-0 bg-gradient-to-br from-blue-900 to-purple-900 opacity-50"></div>
            <div className="absolute inset-0 bg-grid-pattern"></div>
          </div>
          <motion.div 
            className="text-center z-10 max-w-4xl mx-auto px-6"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h2 className="text-4xl sm:text-5xl md:text-6xl font-bold mb-6 leading-tight font-display">
              {t('hero.headline')}
            </h2>
            <p className="text-xl mb-8">
              {t('hero.subheadline')}
            </p>
            <p className="text-lg mb-10">
              {t('hero.hook')}
            </p>
            <motion.button
              className="px-8 py-3 bg-purple-600 rounded-full text-lg font-semibold hover:bg-purple-700 transition-colors"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => scrollToSection('contact')}
            >
              {t('hero.cta')}
            </motion.button>
          </motion.div>
        </section>

        <section id="services" className="py-20 bg-gray-900">
          <div className="container mx-auto px-6">
            <h3 className="text-4xl font-bold mb-12 text-center font-display">{t('services.title')}</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {['web', 'mobile', 'cloud', 'web3'].map((key, index) => (
                <motion.div 
                  key={key}
                  className="bg-gray-800 p-6 rounded-lg shadow-lg hover:shadow-purple-500/50 transition-all"
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  whileHover={{ scale: 1.03 }}
                >
                  <div className="flex items-center mb-4">
                    <span className="text-purple-400 mr-4">{serviceIcons[key]}</span>
                    <h4 className="text-2xl font-semibold font-display">{t(`services.${key}.title`)}</h4>
                  </div>
                  <p className="mb-4">{t(`services.${key}.description`)}</p>
                  <ul className="list-disc list-inside text-purple-300">
                    {t(`services.${key}.benefits`, { returnObjects: true }).map((benefit, i) => (
                      <li key={i}>{benefit}</li>
                    ))}
                  </ul>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        <section id="expertise" className="py-20 bg-black">
          <div className="container mx-auto px-6">
            <h3 className="text-4xl font-bold mb-12 text-center font-display">{t('expertise.title')}</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {t('expertise.areas', { returnObjects: true }).map((area, index) => (
                <motion.div 
                  key={index}
                  className="bg-gray-800 p-6 rounded-lg shadow-lg hover:shadow-purple-500/50 transition-all"
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  whileHover={{ scale: 1.03 }}
                >
                  <h4 className="text-2xl font-semibold mb-4 text-purple-400 font-display">{area.title}</h4>
                  <p className="mb-4">{area.description}</p>
                  <ul className="list-disc list-inside text-blue-300">
                    {area.points.map((point, i) => (
                      <li key={i}>{point}</li>
                    ))}
                  </ul>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        <section id="portfolio" className="py-20 bg-gray-900">
          <div className="container mx-auto px-6">
            <h3 className="text-4xl font-bold mb-12 text-center font-display">{t('portfolio.title')}</h3>
            <p className="text-xl mb-10 text-center">{t('portfolio.description')}</p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {t('portfolio.projects', { returnObjects: true }).map((project, index) => (
                <motion.div
                  key={index}
                  className="bg-gray-800 rounded-lg overflow-hidden shadow-lg hover:shadow-purple-500/50 transition-all"
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  whileHover={{ scale: 1.05 }}
                >
                  <img src={project.image} alt={project.title} className="w-full h-48 object-cover" />
                  <div className="p-6">
                    <h4 className="text-2xl font-semibold mb-2 text-purple-400 font-display">{project.title}</h4>
                    <p className="text-gray-300">{project.description}</p>
                    <button className="mt-4 px-4 py-2 bg-purple-600 text-white rounded-full hover:bg-purple-700 transition-colors">
                      {t('portfolio.learnMore')}
                    </button>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        <section id="testimonials" className="py-20 bg-black">
          <div className="container mx-auto px-6">
            <h3 className="text-4xl font-bold mb-12 text-center font-display">{t('testimonials.title')}</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
              {t('testimonials.list', { returnObjects: true }).map((testimonial, index) => (
                <motion.div 
                  key={index}
                  className="bg-gray-900 p-6 rounded-lg shadow-lg border border-purple-500"
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <p className="mb-4 italic">&quot;{testimonial.text}&quot;</p>
                  <p className="font-bold text-purple-400">{testimonial.author}</p>
                  <p className="text-sm text-blue-300">{testimonial.company}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        <section id="contact" className="py-20 bg-gray-900">
          <div className="container mx-auto px-6">
            <h3 className="text-4xl font-bold mb-10 text-center font-display">{t('contact.title')}</h3>
            <p className="text-xl mb-8 text-center">{t('contact.subtitle')}</p>
            <form 
              className="max-w-lg mx-auto" 
              action="https://formspree.io/f/xkgwayon" 
              method="POST"
            >
              <input 
                type="email" 
                name="email" 
                placeholder={t('contact.email')} 
                className="w-full p-3 mb-4 rounded bg-gray-800 text-white border border-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-600" 
                aria-label={t('contact.email')} 
                required 
              />
              <textarea 
                name="message" 
                placeholder={t('contact.message')} 
                className="w-full p-3 mb-4 rounded bg-gray-800 text-white border border-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-600" 
                rows="4" 
                aria-label={t('contact.message')}
                required
              ></textarea>
              {/* Hidden input to specify the redirect URL after submission */}
              <input type="hidden" name="_next" value="/thank-you" />
              <motion.button 
                type="submit" 
                className="w-full bg-purple-600 text-white px-6 py-3 rounded-full hover:bg-purple-700 transition-colors text-lg font-semibold"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                {t('contact.send')}
              </motion.button>
            </form>
            <p className="text-center mt-6">{t('contact.callToAction')}</p>
          </div>
        </section>
      </main>

      <footer className="bg-gray-900 text-center py-8">
        <div className="container mx-auto px-6">
          <p className="mb-4">{t('footer')}</p>
          <div className="flex justify-center space-x-4 mb-4">
            <a href="https://www.linkedin.com/company/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn" className="text-purple-400 hover:text-purple-300"><Linkedin /></a>
          </div>
          <nav>
            <ul className="flex justify-center space-x-4">
              {['services', 'expertise', 'portfolio', 'testimonials', 'contact'].map((item) => (
                <li key={item}>
                  <button 
                    onClick={() => scrollToSection(item)} 
                    className="hover:text-purple-400 transition-colors"
                  >
                    {t(`nav.${item}`)}
                  </button>
                </li>
              ))}
              <li>
                <Link 
                  to="/rentals" 
                  className="hover:text-purple-400 transition-colors"
                >
                  {t('nav.rentals')}
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </footer>

      <AnimatePresence>
        {showScrollTop && (
          <motion.button
            className="fixed bottom-4 right-4 bg-purple-600 text-white p-3 rounded-full shadow-lg"
            onClick={scrollToTop}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            aria-label={t('backToTop')}
          >
            <ArrowUp />
          </motion.button>
        )}
      </AnimatePresence>
    </div>
  );
}

export default MainPage;