import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import { Globe, Edit3, Settings, Users, RefreshCw, ArrowUp, Check, Phone, Mail, MapPin, Star, Clock, Lock, User } from 'lucide-react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ThankYou from './ThankYou';

// Tooltip component
const Tooltip = ({ children, text }) => (
  <div className="relative group">
    {children}
    <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
      {text}
    </div>
  </div>
);

function RentalLanding() {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState('en');
  const [isNavSticky, setIsNavSticky] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLang(lng);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsNavSticky(window.scrollY > 100);
      setShowScrollTop(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const benefits = t('rental.benefits.list', { returnObjects: true });
  const testimonials = t('rental.testimonials.list', { returnObjects: true });
  const faqItems = t('rental.faq.questions', { returnObjects: true });
  const pricingPlans = t('rental.pricing.plans', { returnObjects: true });
  const journeySteps = t('rental.journey.steps', { returnObjects: true });

  const icons = [Globe, Edit3, Settings, Users, RefreshCw, Check];

  return (
    <div className="bg-gradient-to-b from-gray-900 to-black text-white">
      {/* Header */}
      <header className={`fixed w-full z-50 transition-all duration-300 ${isNavSticky ? 'bg-black bg-opacity-80 backdrop-filter backdrop-blur-lg' : ''}`}>
        <nav className="container mx-auto px-6 py-4 flex justify-between items-center">
          <Link to="/" className="flex items-center">
            <img src="/images/logo.png" alt="Codolie Logo" className="h-12 w-auto mr-2" />
            <motion.h1 
              className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600 font-display cursor-pointer"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Codolie
            </motion.h1>
          </Link>
          
          <div className="flex items-center space-x-6">
            <select 
              value={lang} 
              onChange={(e) => changeLanguage(e.target.value)}
              className="bg-purple-600 text-white p-2 rounded-md"
            >
              <option value="en">EN</option>
              <option value="fr">FR</option>
              <option value="es">ES</option>
            </select>
            
            <Link to="/temporary">
              <Tooltip text="Client Login">
                <motion.button 
                  className="bg-purple-600 text-white p-2 rounded-md hover:bg-purple-700 transition-colors duration-300 ease-in-out"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <span className="hidden md:inline">Client Login</span>
                  <User className="md:hidden" size={24} />
                </motion.button>
              </Tooltip>
            </Link>
          </div>
        </nav>
      </header>

      {/* Hero Section with Lead Capture Form and Subtle Animations */}
      <section id="hero" className="min-h-screen flex items-center justify-center relative overflow-hidden py-20">
        {/* Background gradient and pattern */}
        <div className="absolute inset-0 z-0">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-900 via-purple-800 to-black opacity-70"></div>
        </div>

        <div className="container mx-auto px-6 z-10">
          <div className="flex flex-col md:flex-row items-center justify-between">
            
            {/* Hero text content */}
            <motion.div 
              className="text-center md:text-left md:w-1/2 mb-10 md:mb-0"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-6 leading-tight font-display">
                {t('rental.hero.headline').split('.').map((sentence, index) => (
                  <React.Fragment key={index}>
                    {sentence.trim()}
                    {index < t('rental.hero.headline').split('.').length - 1 && <br />}
                  </React.Fragment>
                ))}
              </h2>
              <p className="text-xl mb-8">
                {t('rental.hero.subheadline')}
              </p>
              
              {/* CTA Buttons */}
              <div className="flex flex-col sm:flex-row justify-center md:justify-start space-y-4 sm:space-y-0 sm:space-x-4">
                <motion.button
                  className="px-8 py-3 bg-purple-600 rounded-full text-lg font-semibold hover:bg-purple-700 transition-colors"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => scrollToSection('contact')}
                >
                  {t('rental.hero.cta1')}
                </motion.button>
                <motion.button
                  className="px-8 py-3 bg-blue-600 rounded-full text-lg font-semibold hover:bg-blue-700 transition-colors"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {t('rental.hero.cta2')}
                </motion.button>
              </div>
              <p className="mt-4 text-sm opacity-75">{t('rental.hero.disclaimer')}</p>
            </motion.div>

            {/* Lead Capture Form */}
            <motion.div 
              className="md:w-1/3 bg-white bg-opacity-10 p-6 rounded-lg shadow-lg"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              <h3 className="text-2xl font-bold mb-4">{t('rental.hero.leadForm.title')}</h3>
              <form 
                action="https://formspree.io/f/xgvwbdvg" 
                method="POST" 
                onSubmit={(e) => {
                  e.target.action = "https://formspree.io/f/xgvwbdvg";
                  e.target.redirect = "/thank-you";
                }} 
                className="space-y-4"
              >
                <input type="text" name="name" placeholder={t('rental.hero.leadForm.name')} className="w-full p-2 rounded bg-gray-800 text-white" required />
                <input type="email" name="email" placeholder={t('rental.hero.leadForm.email')} className="w-full p-2 rounded bg-gray-800 text-white" required />
                <input type="url" name="website" placeholder={t('rental.hero.leadForm.website')} className="w-full p-2 rounded bg-gray-800 text-white" />
                <select name="businessType" className="w-full p-2 rounded bg-gray-800 text-white">
                  <option value="">{t('rental.hero.leadForm.businessType')}</option>
                  <option value="retail">Retail</option>
                  <option value="service">Service</option>
                  <option value="restaurant">Restaurant</option>
                  <option value="other">Other</option>
                </select>
                <button type="submit" className="w-full bg-purple-600 text-white py-2 rounded-full hover:bg-purple-700 transition-colors">
                  {t('rental.contact.send')}
                </button>
              </form>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Social Proof Section */}
      <section className="py-20 bg-gray-800">
        <div className="container mx-auto px-6 text-center">
          <h3 className="text-3xl font-bold mb-8 font-display">{t('rental.socialProof.title')}</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {t('rental.socialProof.metrics', { returnObjects: true }).map((metric, index) => (
              <motion.div 
                key={index}
                className="bg-gray-700 p-6 rounded-lg shadow-lg hover:shadow-purple-500/50 transition-all"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <p className="text-4xl font-bold text-purple-400 mb-2">{metric.value}</p>
                <p className="text-lg">{metric.label}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section id="services" className="py-20 bg-gray-900">
        <div className="container mx-auto px-6">
          <h3 className="text-4xl font-bold mb-12 text-center font-display">{t('rental.benefits.title')}</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
            {benefits.map((benefit, index) => {
              const Icon = icons[index] || Check;
              return (
                <motion.div 
                  key={index}
                  className="bg-gray-800 p-6 rounded-lg shadow-lg hover:shadow-purple-500/50 transition-all"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <Icon className="text-purple-400 w-12 h-12 mb-4" />
                  <h4 className="text-xl font-semibold mb-2">{benefit.title}</h4>
                  <p>{benefit.description}</p>
                </motion.div>
              );
            })}
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section id="pricing" className="py-20 bg-gray-900">
        <div className="container mx-auto px-6">
          <h3 className="text-4xl font-bold mb-12 text-center font-display">{t('rental.pricing.title')}</h3>
          <p className="text-xl mb-12 text-center">{t('rental.pricing.description')}</p>
          <p className="text-lg mb-8 text-center text-purple-400">{t('rental.pricing.annualDiscount')}</p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {pricingPlans.map((plan, index) => (
              <motion.div
                key={index}
                className={`bg-gray-800 p-8 rounded-lg shadow-lg hover:shadow-purple-500/50 transition-all ${plan.popular ? 'border-2 border-purple-500 relative' : ''}`}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                {plan.popular && (
                  <span className="absolute top-0 right-0 bg-purple-500 text-white px-2 py-1 text-sm rounded-bl">Most Popular</span>
                )}
                <h4 className="text-2xl font-bold mb-4">{plan.name}</h4>
                <p className="text-4xl font-bold mb-6">{plan.price}<span className="text-xl font-normal">{plan.period}</span></p>
                <ul className="mb-8">
                  {plan.features.map((feature, i) => (
                    <li key={i} className="flex items-center mb-2">
                      <Check className="text-green-400 mr-2" size={16} />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
                <Link to="/temporary">
                  <motion.button
                    className={`w-full py-2 rounded-full font-semibold ${plan.popular ? 'bg-purple-600 hover:bg-purple-700' : 'bg-blue-600 hover:bg-blue-700'} transition-colors`}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    {plan.cta}
                  </motion.button>
                </Link>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Guarantee Section */}
      <section className="py-24 bg-gray-900">
        <div className="container mx-auto px-6">
          <h2 className="text-5xl font-bold mb-16 text-center font-display text-white">
            {t('rental.guarantee.title')}
          </h2>
          <div className="bg-gray-800 rounded-xl shadow-2xl p-12 hover:shadow-purple-500/50 transition-all">
            <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="md:w-1/2 text-left mb-8 md:mb-0">
                <p className="text-xl text-gray-300">
                  {t('rental.guarantee.description')}
                </p>
              </div>
              <div className="md:w-1/2 flex justify-center">
                <motion.div 
                  className="bg-purple-600 rounded-full w-64 h-64 flex items-center justify-center"
                  whileHover={{ scale: 1.05 }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  <div className="text-white text-center">
                    <p className="text-5xl font-bold mb-2">15</p>
                    <p className="text-2xl font-semibold">DAY</p>
                    <p className="text-xl">Money-Back</p>
                    <p className="text-2xl font-bold">Guarantee</p>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Customer Journey Section */}
      <section className="py-24 bg-gradient-to-br from-blue-900 via-purple-800 to-black opacity-70">
        <div className="container mx-auto px-6">
          <h2 className="text-5xl font-bold mb-16 text-center font-display text-white">
            {t('rental.journey.title')}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-8">
            {journeySteps.map((step, index) => (
              <motion.div
                key={index}
                className="bg-gray-800 p-8 rounded-xl shadow-2xl hover:shadow-purple-500/50 transition-all"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                whileHover={{ scale: 1.05 }}
              >
                <div className="flex flex-col items-center mb-6">
                  <span className="text-4xl font-bold text-purple-500 mb-4">{index + 1}</span>
                  <h3 className="text-2xl font-semibold text-center text-white">{step.title}</h3>
                </div>
                <p className="text-gray-300 text-center">{step.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section id="testimonials" className="py-20 bg-gray-800">
        <div className="container mx-auto px-6">
          <h3 className="text-4xl font-bold mb-12 text-center font-display">{t('rental.testimonials.title')}</h3>
          <Carousel showThumbs={false} infiniteLoop useKeyboardArrows autoPlay>
            {testimonials.map((testimonial, index) => (
              <motion.div 
                key={index} 
                className="bg-gray-700 p-8 rounded-lg shadow-lg hover:shadow-purple-500/50 transition-all max-w-2xl mx-auto"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <p className="text-xl italic mb-4">&quot;{testimonial.text}&quot;</p>
                <div className="flex items-center justify-center">
                  <div className="mr-4">
                    <p className="font-bold text-purple-400">{testimonial.author}</p>
                    <p className="text-sm text-gray-400">{testimonial.business}</p>
                  </div>
                  <Star className="text-yellow-400" size={24} />
                </div>
              </motion.div>
            ))}
          </Carousel>
        </div>
      </section>

      {/* FAQ Section */}
      <section id="faq" className="py-20 bg-gray-800">
        <div className="container mx-auto px-6">
          <h3 className="text-4xl font-bold mb-12 text-center font-display">{t('rental.faq.title')}</h3>
          <div className="space-y-6 max-w-3xl mx-auto">
            {faqItems.map((faq, index) => (
              <motion.details 
                key={index} 
                className="bg-gray-700 p-6 rounded-lg shadow-lg hover:shadow-purple-500/50 transition-all"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <summary className="font-semibold cursor-pointer text-lg">{faq.question}</summary>
                <p className="mt-4">{faq.answer}</p>
              </motion.details>
            ))}
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="py-20 bg-gray-900">
        <div className="container mx-auto px-6">
          <h3 className="text-4xl font-bold mb-8 text-center font-display">{t('rental.contact.title')}</h3>
          <p className="text-xl mb-12 text-center">{t('rental.contact.subtitle')}</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            {/* Free Consultation Form */}
            <form 
              action="https://formspree.io/f/xkgwayon" 
              method="POST" 
              onSubmit={(e) => {
                e.target.action = "https://formspree.io/f/xkgwayon";
                e.target.redirect = "/thank-you";
              }} 
              className="space-y-4"
>
  <input type="text" name="name" placeholder={t('rental.contact.name')} className="w-full p-3 rounded bg-gray-800 text-white" required />
  <input type="email" name="email" placeholder={t('rental.contact.email')} className="w-full p-3 rounded bg-gray-800 text-white" required />
  <input type="tel" name="phone" placeholder={t('rental.contact.phone')} className="w-full p-3 rounded bg-gray-800 text-white" />
  <textarea name="message" placeholder={t('rental.contact.message')} rows="4" className="w-full p-3 rounded bg-gray-800 text-white"></textarea>
  <motion.button 
    type="submit" 
    className="w-full bg-purple-600 text-white px-6 py-3 rounded-full hover:bg-purple-700 transition-colors text-lg font-semibold"
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
                {t('rental.contact.send')}
              </motion.button>
            </form>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-900 py-10">
        <div className="container mx-auto px-6 text-center">
          <p> {t('rental.footer')}</p>
          <div className="mt-4 flex justify-center space-x-4">
            <a href="#" className="text-purple-400 hover:text-purple-300">
              <span className="sr-only">Facebook</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" />
              </svg>
            </a>
            <a href="#" className="text-purple-400 hover:text-purple-300">
              <span className="sr-only">Instagram</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clipRule="evenodd" />
              </svg>
            </a>
            <a href="#" className="text-purple-400 hover:text-purple-300">
              <span className="sr-only">Twitter</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
              </svg>
            </a>
          </div>
        </div>
      </footer>

      {/* Back to Top Button */}
      <AnimatePresence>
        {showScrollTop && (
          <motion.button
            className="fixed bottom-4 right-4 bg-purple-600 text-white p-3 rounded-full shadow-lg"
            onClick={scrollToTop}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            aria-label={t('backToTop')}
          >
            <ArrowUp />
          </motion.button>
        )}
      </AnimatePresence>
    </div>
  );
}

export default RentalLanding;