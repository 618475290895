import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainPage from './MainPage';
import RentalLanding from './RentalLanding';
import ThankYou from './ThankYou';
import TemporaryPage from './TemporaryPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/rentals" element={<RentalLanding />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/temporary" element={<TemporaryPage />} />
      </Routes>
    </Router>
  );
}

export default App;