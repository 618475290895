import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/translation.json';
import translationFR from './locales/fr/translation.json';
import translationES from './locales/es/translation.json';


const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  es: {
    translation: translationES,
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: ['en', 'fr', 'es'],
    defaultNS: 'translation',  // Set default namespace
    ns: 'translation',  // Include all namespaces
    detection: {
      order: ['localStorage', 'navigator', 'htmlTag'],
      caches: ['localStorage'],  // Cache detected language in localStorage
    },
    interpolation: {
      escapeValue: false // React already escapes values
    },
    debug: true,
    saveMissing: true,
    missingKeyHandler: (lng, ns, key, fallbackValue) => {
      console.log('Missing key:', key);
      console.log('Fallback value:', fallbackValue);
    },
  });

export default i18n;
